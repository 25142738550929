<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
    <div>
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" />
        
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:20px !important;`">
            <v-col cols="12" md="12" sm="12">
                <form autocomplete="off">
                <v-row>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.start_date}}</label>
                        <b-input-group>
                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.end_date}}</label>
                        <b-input-group>
                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.mobile}}</label>
                        <b-input-group>
                        <b-form-input class="inborder" v-model="mobile" style="background:#c3d9ff;" />
                        </b-input-group>
                    </v-col>

                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.voutcher_no}}</label>
                        <b-input-group>
                        <b-form-input class="inborder" v-model="voutcherid" style="background:#c3d9ff;" />
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label></label>
                        <b-input-group style="margin-top:-5px">
                        <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getVouchers()">{{lang.search}}</b-button>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label></label>
                        <b-input-group style="margin-top:-5px">
                        <b-button variant="light" style="background:blue;color:#fff;width:75px;" v-b-toggle.add_outreceipt  @click="resetAll()">{{lang.add}}</b-button>
                        </b-input-group>
                    </v-col>
                </v-row>
                </form>
            </v-col>
        </v-row>
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:20px !important;`">
            <v-col cols="12" md="12" sm="12">
                <template>
                    <v-data-table
                        :headers="headers"
                        :items="outrows"
                        disable-pagination
                        hide-default-footer
                        class="elevation-1"
                        style="padding-left:30px;padding-right:30px;"
                    >
                        <template v-slot:item="row">
                        <tr>
                            <td class="text-center" style="width:150px;">{{ row.item.voutcherid }}</td>
                            <td class="text-center" style="width:150px;">{{ row.item.received_from }}</td>
                            <td class="text-center" style="width:150px;">{{ row.item.receipt_date.substr(0,10) }}</td>
                            <td class="text-center">{{ row.item.pay_reson }}</td>
                            <!-- <td class="text-center" style="width:100px;">{{ row.item.total }}</td> -->
                            <!-- <td class="text-center" style="width:100px;">{{ row.item.vat }}</td> -->
                            <td class="text-center" style="width:100px;">{{ $convertToComa($RoundNums(row.item.ftotal,2),$store.state.licenseType.moneyComma) }}</td>
                            <td class="text-center" style="width:75px;background:blue;color:#FFF;border:2px solid #FFF !important;">
                                <v-btn style="font-size:0.2em;background:blue;width:75px;color:#FFF;height:25px !important;" @click="printPDF(row.item.id)">{{lang.print_voucher}}</v-btn>
                            </td>
                        </tr>
                        </template>
                    </v-data-table>
                
                </template>

            </v-col>
            <v-col cols="12" class="md-3" :style="`direction:`+lang.dir+` ;`">
                <v-row :style="`direction:`+lang.ldir+`;margin-top:-10px;margin-left:5px`">
                    <v-col cols="12" md="3" sm="6">
                        <b-table-simple :style="`margin-inline-end:15px;direction:`+lang.dir">
                            <thead>
                                <tr>
                                    <th class="backRed" :style="`text-align:`+lang.lalgin+`;`">{{lang.total}}</th>
                                    <td :style="`background:lightgreen;text-align:`+lang.algin+`;`">{{ $convertToComa($RoundNums(all_totals.total),$store.state.licenseType.moneyComma) }}</td>
                                </tr>
                                <tr>
                                    <th class="backGreen" :style="`text-align:`+lang.lalgin+`;`">{{lang.vat}}</th>
                                    <td :style="`background:lightgreen;text-align:`+lang.algin+`;`">{{ $convertToComa($RoundNums(all_totals.vat),$store.state.licenseType.moneyComma) }}</td>
                                </tr>
                                <tr>
                                    <th class="backBlack" :style="`text-align:`+lang.lalgin+`;`">{{lang.ftotal}}</th>
                                    <td :style="`text-align:`+lang.algin+`;`">{{ $convertToComa($RoundNums(all_totals.ftotal,2),$store.state.licenseType.moneyComma) }}</td>
                                </tr>
                            </thead>
                        </b-table-simple>
                    </v-col>
                    
                </v-row>
            </v-col>
        </v-row>
        <outReceipt ref="outReceipt" />
    </div>

        <Footer />
        <div class="loadingPage" v-if="$store.state.showLoading">
            <v-progress-circular
                :width="3"
                color="green"
                indeterminate
            ></v-progress-circular>
            </div>
    </div>
</template>

<script>
import axios from 'axios'
import outReceipt from '../components/outReceipt.vue'
import BreadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue'
import Footer from '@/components/Footer.vue'
import ExportsPrint from '@/components/exports-print.vue';
export default{
    components:{
        outReceipt,
        BreadCrumbs,
        TabsComp,
        Footer,
        HeaderPortrate,
        ExportsPrint
    },
    data() {
     return {
            
            outrows: [
                
            ],
            all_total: 0,
            all_ftotal: 0,
            all_vat: 0,
            active_tab:1,
            mobile: '',
            voutcherid: '',
            sd: {
                day: 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            }
        }
    },
    created() {
        this.getStartDate()
        this.getVouchers();
    },
    methods: {
        resetAll(){
            this.$refs.outReceipt.resetAll();
        },
        getStartDate(){
            let d = (new Date()).getDate();
            let m = (new Date()).getMonth() + 1;
            let y = (new Date()).getFullYear();
            if(parseInt(d) < 30){
            d = parseInt(30) - parseInt(d);
            if(parseInt(m) == 1){
                m = 12
                y = parseInt(y) - 1
            }
            else if(parseInt(m) > 1){
                m = parseInt(m) - 1;
            }
            }else if(parseInt(d) == 30){
            d = 1;
            }else{
            d = parseInt(d) - parseInt(30);
            }
            this.sd.day = 1;
            this.sd.month = 1;
            this.sd.year = y;
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?recpid='+id;
        },
        printMe(item){
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('id',item.id);
        },
        ExportIt(type){
            let url = this.$store.state.SAMCOTEC.e_path+'?';
            if(type != 'e'){
                let url = this.$store.state.SAMCOTEC.pd_path+'?inrecepit';
            }
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('sdate','');
            post.append('edate','');
            axios.post(
                url, post
            ).then((res) => {
                var rest = res.data;
                window.open('../api' + rest.url,'_blank')
            })
        },
        getVouchers(){
            const post = new FormData();
            post.append("type" , 'getVoucher');
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append('data[sdate]',this.sdate);
            post.append('data[todate]',this.edate);
            post.append('data[mobile]',this.mobile);
            post.append('data[voutcherid]',this.voutcherid);
            post.append('data[type]',2);
            let all_total = 0;
            let all_ftotal = 0;
            let all_vat = 0;
            this.$store.state.showLoading = true
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    this.$store.state.showLoading = false
                    const res = response.data;
                    if(res.results.data != false)
                    {
                        this.outrows = res.results.data;
                   
                        if(this.outrows.length != 0){
                            for(let i = 0;i<this.outrows.length ; i++){
                                all_total = parseFloat(all_total) + parseFloat(this.outrows[i].total);
                                all_ftotal = parseFloat(all_ftotal) + parseFloat(this.outrows[i].ftotal);
                                all_vat = parseFloat(all_vat) + parseFloat(this.outrows[i].vat);
                            }
                        }
                    }
                    this.all_total = this.$RoundNum(all_total);
                    this.all_ftotal = this.$RoundNum(all_ftotal);
                    this.all_vat = this.$RoundNum(all_total);
                }
            )
        },
        preparedata(){
            let exdata = {
                list: "outReceList",
                title: this.lang.exchange_voucher,
                data: [],
            }
            for(let i=0;i<this.outrows.length;i++){
                exdata.data.push(this.outrows[i]);
            }
            // // console.log(exdata);
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = this.sdate;
            this.$refs.exportbar.edate = this.sdate;
            
            this.$refs.exportbar.exporttitle = "";
            this.$refs.exportbar.palte_number = '';
            this.$refs.exportbar.contact_number = '';
         
        },
    },
    computed: {
        all_totals: function() {
            let a = {
                total: 0,
                vat: 0,
                ftotal: 0
            };
            for(let i = 0;i<this.outrows.length;i++){
                a.total = parseFloat(a.total) + parseFloat(this.outrows[i].total);
                a.vat = parseFloat(a.vat) + parseFloat(this.outrows[i].vat);
                a.ftotal = parseFloat(a.ftotal) + parseFloat(this.outrows[i].ftotal);
            }
            return a;
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        todate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
            const start_year = this.$store.state.licenseType.startYear;
            let t = [{text: this.lang.year, value: -1}];
            for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
                t.push({text: start_year + i, value: start_year+i})
            }
            
            }
            return t
        },
        daysList: function(){
            return [
                {text: this.lang.day, value: -1},
                {text: 1, value: 1},
                {text: 2, value: 2},
                {text: 3, value: 3},
                {text: 4, value: 4},
                {text: 5, value: 5},
                {text: 6, value: 6},
                {text: 7, value: 7},
                {text: 8, value: 8},
                {text: 9, value: 9},
                {text: 10, value: 10},
                {text: 11, value: 11},
                {text: 12, value: 12},
                {text: 13, value: 13},
                {text: 14, value: 14},
                {text: 15, value: 15},
                {text: 16, value: 16},
                {text: 17, value: 17},
                {text: 18, value: 18},
                {text: 19, value: 19},
                {text: 20, value: 20},
                {text: 21, value: 21},
                {text: 22, value: 22},
                {text: 23, value: 23},
                {text: 24, value: 24},
                {text: 25, value: 25},
                {text: 26, value: 26},
                {text: 27, value: 27},
                {text: 28, value: 28},
                {text: 29, value: 29},
                {text: 30, value: 30},
                {text: 31, value: 31},
            ]
        },
        monthsList: function(){
            return [
                {text: this.lang.chose_month, value: -1},
                {text: 1, value: 1},
                {text: 2, value: 2},
                {text: 3, value: 3},
                {text: 4, value: 4},
                {text: 5, value: 5},
                {text: 6, value: 6},
                {text: 7, value: 7},
                {text: 8, value: 8},
                {text: 9, value: 9},
                {text: 10, value: 10},
                {text: 11, value: 11},
                {text: 12, value: 12},
            ]
        },
        tabs: function(){
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        items: function() {
            return {
                    text: this.lang.exchange_voucher,
                    disabled: true,
                    to: '/finance/outreceipt',
                }
        },
        headers: function(){ 
            return [
                { text: this.lang.voutcher_no, value: 'voucherid',sortable: false,class:"backBlack text-center" },
                { text: this.lang.receive_by, value: 'received_by',sortable: false,class:"backBlack text-center" },
                { text: this.lang.date, value: 'receipt_date',sortable: false,class:"backBlack text-center" },
                { text: this.lang.voutcher_resone, value: 'pay_reson',sortable: false,class:"backBlack text-center"},   
                // { text: this.lang.total, value: 'total',sortable: false,class:"backRed text-center" },
                // { text: this.lang.vat + ' 15%', value: 'vat',sortable: false,class:"backGreen text-center" },
                { text: this.lang.ftotal, value: 'ftotal',sortable: false,class:"backBlack text-center" },
                { text: this.lang.action, value: 'ftotal',sortable: false,class:"backBlack text-center" },
            ]
        }
    },
}
</script>
<style>
.loadingPage{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background:#ffffff50;
  z-index: 100000;
}
</style>